<template>
  <div v-loading="loading">
    <notes-form
      ref="company-notes"
      label="Company Notes"
      button-label="Edit Company Notes"
      :notes="companyNotes"
      @save="saveNotes({ notes: $event })"
    />
    <notes-form
      ref="service-notes"
      label="Service Notes"
      button-label="Edit Service Notes"
      :notes="serviceNotes"
      @save="saveNotes({ service_notes: $event })"
    />
  </div>
</template>

<script>
import CompanyNotesForm from './forms/CompanyNotesForm'
import RooofAPI from '@/services/api/rooof'

export default {
  name: 'CompanyOverviewNotes',
  components: {
    'notes-form': CompanyNotesForm
  },
  data () {
    return {
      companyNotes: '',
      serviceNotes: '',
      loading: false
    }
  },
  created () {
    this.fetchCompany()
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['company-notes'].dataModified || this.$refs['service-notes'].dataModified) {
      const answer = window.confirm('Form data has been modified without saving. Continue?')
      if (!answer) {
        this.$emit('navigation-cancel')
        return false
      }
    }
    this.$refs['company-notes'].cancel()
    this.$refs['service-notes'].cancel()
    next()
  },
  methods: {
    /**
     * Fetches the company notes from the API
     */
    async fetchCompany () {
      try {
        this.loading = true
        const company = await RooofAPI.companies.retrieve(this.$route.params.cid)
        this.companyNotes = company.notes
        this.serviceNotes = company.service_notes
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Saves notes to the API in markdown.
     *
     * @param {Object} payload
     */
    async saveNotes (payload) {
      try {
        this.loading = true
        await RooofAPI.companies.partialUpdate(this.$route.params.cid, payload)
        await this.fetchCompany()
        this.$message({
          message: `${payload.service_notes ? 'Service' : 'Company'} notes saved!`,
          type: 'success',
          duration: 3500
        })
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    }
  }
}
</script>
